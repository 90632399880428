import axios from "./api";

const base = "/business/chat";

const Chat = {
  createConversation(data) {
    return axios.post("/business/createConversation", data);
  },
  updateConversation(data) {
    return axios.post("/business/updateConversation", data);
  },
  getConversationByID(id) {
    return axios.get(`/business/getConversation/${id}`);
  },
  getChatsByLocation(id) {
    return axios.get(`/business/conversation/location/${id}`);
  },
  deleteConversationByID(id) {
    return axios.delete(`/business/deleteConversationById/${id}`);
  },

  //old api
  createToken(data) {
    return axios.post(`${base}/create-token`, data);
  },
  createChat(data) {
    return axios.post(`${base}`, data);
  },
  deleteChat(id) {
    return axios.delete(`${base}/${id}`);
  },
  chatParticipants(id) {
    return axios.get(`${base}/participants/${id}`);
  },
  updateLastSeen(id) {
    return axios.patch(`${base}/last-seen/${id}`);
  },
  chatByLocation(id) {
    return axios(`${base}/location/${id}`);
  },
  getConversationByConversationSID(sid) {
    return axios.get(`${base}/conversation/${sid}`);
  },
  join(id, locationId) {
    return axios.post(`${base}/join/${id}`, {
      location_id: locationId,
    });
  },
};

export default Chat;
