import React, { useEffect, useRef } from "react";
import Chart from "react-apexcharts";

const JobPieChart = ({
  data = [],
  statCount = {},
  isInView = false,
  setIsInView = () => {},
  ongoingChats = [],
  mode = false,
  conversationLoading = false,
}) => {
  const chartRef = useRef(null);

  const calculateStatistics = () => {
    // Add type checks and default values
    if (
      !Array.isArray(data) ||
      !Array.isArray(ongoingChats) ||
      typeof statCount !== "object" ||
      !statCount
    ) {
      return {
        hiredCount: 0,
        chatConversationCount: 0,
        noChatConversationCount: 0,
        total: 0,
      };
    }

    const hiredCount = Number(statCount?.total_hired) || 0;

    // Filter out invalid data first
    const appliedJobSeekers = new Set(
      data
        .filter(
          (item) =>
            item?.job_seeker?.status === "applied" &&
            item?.job_seeker?.job_seeker_id
        )
        .map((item) => item?.job_seeker?.job_seeker_id)
    );

    let chatConversationCount = 0;
    let noChatConversationCount = 0;

    appliedJobSeekers.forEach((jobSeekerId) => {
      if (
        jobSeekerId &&
        ongoingChats.some((chat) => chat?.job_seeker_id === jobSeekerId)
      ) {
        chatConversationCount++;
      } else {
        noChatConversationCount++;
      }
    });

    const total = hiredCount + chatConversationCount + noChatConversationCount;

    return {
      hiredCount,
      chatConversationCount,
      noChatConversationCount,
      total,
    };
  };

  useEffect(() => {
    if (!chartRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry?.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    observer.observe(chartRef.current);

    return () => observer.disconnect();
  }, []);

  const stats = calculateStatistics();

  const chartOptions = stats
    ? {
        colors: ["#594A9E", "#AA73A6", "#FFC9F1"],
        chart: {
          type: "pie",
          background: mode ? "transparent" : "#242526",
          foreColor: mode ? "#000000" : "#ffffff",
        },
        states: {
          hover: {
            filter: {
              type: "darken",
              value: 0.25,
            },
          },
        },
        stroke: {
          colors: ["white"],
          lineCap: "",
        },
        plotOptions: {
          pie: {
            labels: {
              show: true,
            },
            size: "100%",
            dataLabels: {
              offset: -25,
            },
          },
        },
        labels: ["Hired", "Conversation Started", "Conversation Not Started"],
        tooltip: {
          y: {
            formatter: function (value) {
              const percentage = ((value / stats.total) * 100).toFixed(1);
              return `${value} (${percentage}%)`;
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "Inter, sans-serif",
          },
        },
        legend: {
          position: "bottom",
          fontFamily: "Inter, sans-serif",
          labels: {
            colors: mode ? "#000000" : "#FFFFFF",
          },
          onItemHover: {
            highlightDataSeries: false,
          },
        },
      }
    : null;

  const chartSeries = stats
    ? [
        stats.hiredCount,
        stats.chatConversationCount,
        stats.noChatConversationCount,
      ]
    : null;

  const renderContent = () => {
    if (conversationLoading) {
      return (
        <div className="flex justify-center items-center mt-8">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      );
    }

    const stats = calculateStatistics();

    if (!stats || stats.total === 0) {
      return (
        <div className="flex justify-center mt-8 text-gray-500">
          No data available
        </div>
      );
    }

    if (!isInView) {
      return null;
    }

    return (
      <>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div className="rounded-md border border-dashed border-gray-200 p-4">
            <div className="flex items-center mb-0.5">
              <div className="text-xl font-bold dark:text-gray-300">
                {stats.hiredCount}
              </div>
            </div>
            <span className="text-gray-500 text-sm">Hired</span>
          </div>
          <div className="rounded-md border border-dashed border-gray-200 p-4">
            <div className="flex items-center mb-0.5">
              <div className="text-xl font-bold dark:text-gray-300">
                {stats.chatConversationCount}
              </div>
            </div>
            <span className="text-gray-500 text-sm">Conversation Started</span>
          </div>
          <div className="rounded-md border border-dashed border-gray-200 p-4">
            <div className="flex items-center mb-0.5">
              <div className="text-xl font-bold dark:text-gray-300">
                {stats.noChatConversationCount}
              </div>
            </div>
            <span className="text-gray-500 text-sm">
              Conversation Not Started
            </span>
          </div>
        </div>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="pie"
          height={420}
        />
      </>
    );
  };

  return (
    <div className="p-6 lg:col-span-2 relative overflow-hidden rounded-lg px-4 pb-12 pt-5 dark:bg-[#242526] shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-1px_rgba(0,0,0,0.06)] border border-warmgray-200 dark:border-darkGray dark:shadow-none sm:px-6 sm:pt-6 bg-transparent">
      <div className="flex justify-between mb-4 items-start">
        <div className="font-bold text-base dark:text-gray-300">
          HeyHire Statistics - Job Title
        </div>
      </div>
      <div ref={chartRef}>{renderContent()}</div>
    </div>
  );
};

export default JobPieChart;
